export const reviewData = [
   {
      id: 1,
      title: 'Очень хорошее обслуживание!',
      review: 'Я очень довольна обслуживанием и качеством товаров в TД Интех. Купила у них клей для плитки для ремонта ванной комнаты. Все было в наличии, и цены были очень разумные. Отличное и приятное обслуживание, быстро перезвноили и проконсультировали в выборе более подходящих материалов. Доставка была довольно быстрой и приемлемой по цене! Спасибо за вашу работу',
      author: {
         name: 'Владислава Циммерманн',
         position: 'Счастливый клиент'
      }
   },
   {
      id: 2,
      title: 'Отличный выбор!',
      review: 'Отличный выбор! Мы приобрели строительные материалы для ремонта в нашем доме у TД Интех, и остались очень довольны качеством и ценой. Спасибо за отличное обслуживание!',
      author: {
         name: 'Ксения Дарская',
         position: 'Счастливый клиент'
      }
   },
   {
      id: 3,
      title: 'Оперативная и недорогая доставка',
      review: 'Хочу выразить благодарность компании TД Интех за оперативную доставку строительных материалов. Мы заказали цемент и сухие смеси для нашего строительного проекта, и все пришло вовремя и без проблем. Рекомендую!',
      author: {
         name: 'Андрей Сокольник',
         position: 'Счастливый клиент'
      }
   },
]